











































import {Component} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {PercentageProgress},
})
export default class MyWalletAddressCard extends MixinScreenSize {
  get address() {
    if (this.isMobile) {
      return this.$walletAdapter.address?.trim()
        ? `${this.$walletAdapter.address
            .trim()
            .slice(0, 7)}...${this.$walletAdapter.address.trim().slice(-7)}`
        : null
    } else if (this.isLessThanTablet || this.isTablet || this.isSmallDesktop) {
      return this.$walletAdapter.address?.trim()
        ? `${this.$walletAdapter.address
            .trim()
            .slice(0, 10)}...${this.$walletAdapter.address.trim().slice(-10)}`
        : null
    }

    return this.$walletAdapter.address
  }

  copyToClipboard() {
    if (!this.$walletAdapter.address) return

    this.$utils.copyToClipboard(this.$walletAdapter.address)
    this.$toast.info('system.info.copiedToClipboard')
  }
}
