var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"my-wallet-tokens-table"},[(_vm.displayedTokens.length > 0)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"horiz items-center justify-between my-4"},[_c('last-price-updated',{attrs:{"display-inline":true,"last-time-updated":_vm.lastPriceUpdated}}),(!_vm.isMobile && !_vm.isTablet)?_c('powered-by',{attrs:{"items":_vm.poweredByList}}):_vm._e()],1),_c('div',{staticClass:"my-wallet-tokens-table__table-container"},[_c('table',{staticClass:"table table--ndapp"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,key){return _c('th',{key:key},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.displayedTokens),function(item,index){return _c('tr',{key:index},[_c('td',[_c('token-cell-render',{attrs:{"blockchain-tag-name":item.blockchainVersion
                      ? item.blockchainVersion.tagName
                      : '',"image-url":item.imageUrl,"owner-address":item.marketInformation
                      ? item.marketInformation.ownerAddress
                      : '',"slug":item.marketInformation
                      ? item.marketInformation.slugDapp
                      : '',"symbol":item.symbol,"title-dapp":item.marketInformation
                      ? item.marketInformation.titleDapp
                      : ''}})],1),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.balanceFormatted)+" ")])]),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.valueFormatted)+" ")])]),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.priceFormatted)+" ")])]),_c('td',[_c('percentage-progress-render',{attrs:{"percentage-value":item.priceVariationLastDayFormatted,"input-class":"text-lg text-center"}})],1),_c('td',[_c('percentage-progress-render',{attrs:{"percentage-value":item.priceVariationLastWeekFormatted,"input-class":"text-lg text-center"}})],1),_c('td',[_c('tokens-swap-button',{attrs:{"is-swappable":true,"token":item,"is-my-wallet-page":""}})],1)])}),0)])]),(_vm.moreItemsAvailableToDisplay.length > 0)?_c('div',{staticClass:"w-full horiz items-center-center mt-8"},[_c('button',{staticClass:"btn btn--contrast my-wallet-tokens-table__load-more-btn",attrs:{"type":"button"},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletTokensTable.loadMore'))+" ")])]):_vm._e()]):_c('span',{staticClass:"py-10 uppercase text-center text-black-600 text-lg font-light"},[_vm._v(" "+_vm._s(_vm.$t('app.noDataToShow'))+" ")])]),(_vm.isMobile || _vm.isTablet)?_c('powered-by',{staticClass:"mt-3",attrs:{"display-inline":true,"items":_vm.poweredByList}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }