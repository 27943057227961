








































import {Vue, Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'

@Component({
  components: {PercentageProgress},
})
export default class MyWalletTotalBalanceCard extends Vue {
  @Prop({type: Number, required: true})
  totalBalanceAmountInDollar!: number
  @Prop({type: Number, required: true}) totalBalancePercentageVariation!: number
}
